<template>
  <div class="d-flex flex-column dashboard px-4 py-4 w-100">

    <p class="my-4 welcome-title">Welcome back</p>

    <div v-if="state.isLoading" class="d-flex mb-4">
      <v-progress-circular class="mx-auto" color="primary" indeterminate></v-progress-circular>
    </div>

    <div v-else class="d-flex flex-row flex-wrap mb-2">

      <div class="cards">

        <div class="card">
          <div class="card-top">
            <span class="card-heading">Clients</span>
          </div>
          <div class="main-content">
            <div class="card-left">
              <Doughnut :chart-options="options" :chart-data="data" :width="150" :height="200" />
            </div>
            <div class="card-right">
              <p> Total : <b>{{ state.stats.clients.total }}</b> </p>
              <p> Available : <b>{{ state.stats.clients.available }}</b> </p>
              <p> Disable : <b>{{ state.stats.clients.disable }}</b> </p>
            </div>
          </div>

        </div>

        <div class="card">
          <div class="card-top">
            <span class="card-heading">Virtual Assistants</span>
          </div>
          <div class="main-content">
            <div class="card-left">
              <Doughnut :chart-options="virtualAssistantOptions" :chart-data="virtualAssistantData" :width="150"
                :height="200" />
            </div>
            <div class="card-right">
              <div>
                <p>Total : <b>{{ state.stats.virtualAssistant.total }}</b> </p>
                <p>Assigned : <b>{{ state.stats.virtualAssistant.assigned }}</b></p>
                <p>Available : <b>{{ state.stats.virtualAssistant.nonAssigned }}</b></p>
              </div>
            </div>
          </div>

        </div>

        <div class="big-box">
          <div class="inner-big-box">
            <div class="inner-box-left">
              <Doughnut :chart-options="virtualAssistantOptions" :chart-data="chartProjectData" :width="100"
                :height="125" />
            </div>
            <div class="inner-box-right">
              <div class="box-title">Projects</div>
              <div class="number-of-projects">
                {{ state.stats.projects }}
              </div>
            </div>
          </div>


          <div class="inner-big-box " style="justify-content: space-between !important;padding: 20px 20px;">

            <div class="inner-box-left">
              <v-icon class="mr-2 inner-box-icons">{{ icons.mdiMessageFast }}</v-icon>
              <router-link to="/conversations" class="box-title router-links">Conversations</router-link>
            </div>
            <div class="inner-box-right">
              <v-icon class="mr-2 inner-box-icons">{{ icons.mdiAccountMultipleCheck }}</v-icon>
              <router-link to="/manage-assignment" class="box-title router-links">Manage Assignments</router-link>
            </div>
          </div>

        </div>

      </div>

    </div>

    <!-- Support messages -->
    <div class="support-message-box mt-3">
      <div class="card-top  mt-3">
        <span class="card-heading" style="font-size:1.25rem;">Support Messages</span>
      </div>
      <div class="support-message-table">
        <v-data-table :headers="headers" :items="clients" item-key="id" class="elevation-1" fixed-header
        :custom-filter="filterOnlyCapsText" :page.sync="page" :loading="loading" :server-items-length="totalItems"
        :items-per-page.sync="itemsPerPage" @update:page="handlePageChange" loading-text="Loading... Please wait">

        <template v-slot:item.created_at="{ item }">
          <span>{{ formatDate(item.created_at) }}</span>
        </template>

        <template v-slot:item.Contact_Us_Attachment="{ item }">
          <p v-for="(media,index) in item.Contact_Us_Attachment" :key="media.id" >
            <a :href="`${s3_url}/${suppoty_bucket}/${media.file_key}`" target="_blank" >
              {{ `Attachment-${index + 1}` }}
            </a>
          </p>
        </template>

      </v-data-table>
      </div>
    </div>

    <v-alert :type="isError ? 'error' : 'success'" class="popup-message" v-if="isVisible">
      {{ message }}
    </v-alert>

  </div>
</template>

<script>
import dayjs from 'dayjs'
import axios from "@axios";
import { useUser } from "@/composables/user/user";
import { reactive, onMounted, ref, computed } from "@vue/composition-api";
import { mdiChevronRight, mdiMessageFast, mdiAccountMultipleCheck } from "@mdi/js";
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'vue-chartjs/legacy'
import TableWithFilters from "@/components/common/TableWithFilters.vue";

ChartJS.register(ArcElement, Tooltip)

export default {
  name: "Dashboard",
  components: {
    Doughnut
  },
  setup() {
    const loading = ref(false)
    const switchloading = ref(false)
    const page = ref(1)
    const itemsPerPage = ref(0)
    const totalItems = ref(0)
    const headers = computed(() => [
      {
        text: 'TimeStamp',
        value: 'created_at',
        width: 200
      },
      {
        text: 'Email',
        value: 'email',
        width: 250
      },
      {
        text: 'Full Name',
        value: 'name',
      },
      {
        text: 'Subject',
        value: 'subject'
      },
      {
        text: 'Message',
        value: 'message',
        width: 300
      },
      {
        text: 'Attachments',
        value: 'Contact_Us_Attachment',
        sortable: false
      },
    ])
    const clients = ref([])
    const isVisible = ref(false)
    const isError = ref(false)
    const message = ref(null)
    const sortBy = ref('created_at')
    const sortDesc = ref(true)

    const { userData } = useUser();
    const SUPPORT_API_PATH = '/support'
    const state = reactive({
      isLoading: false,
      stats: {},
    });

    const s3_url = ref('https://s3.us-east-1.amazonaws.com') 
    const suppoty_bucket = ref("hireheroes-staging-support")
    const chartData = []
    const virtualAssistantChartData = []
    const projectData = []


    const data = reactive({

      labels: ['Available', 'Disable'],
      datasets: [
        {
          // backgroundColor: ['#3998FF', '#5BAAFF'],
          backgroundColor: ['#f85c4f', '#ffc6bd'],
          data: chartData
        }
      ]
    })

    const options = reactive({
      responsive: true,
      maintainAspectRatio: false,
    })

    const virtualAssistantData = reactive({
      labels: ['Assigned', 'Available VAs'],
      datasets: [
        {
          // backgroundColor: ['#74D793', '#92E0AA'],
          backgroundColor: ['#293f69', '#a9b3c6'],
          data: virtualAssistantChartData
        }
      ]
    })

    const virtualAssistantOptions = reactive({
      responsive: true,
      maintainAspectRatio: false
    })

    const chartProjectData = reactive({
      labels: ['Projects'],
      datasets: [
        {
          backgroundColor: ['#84db9e'],
          data: projectData
        }
      ]
    })

    onMounted(async () => {
      await fetchTableData();
      await fetchStats();
    });

    function formatDate(date){
      return dayjs(date).format('D MMMM YYYY hh:mm A')
    }

    async function fetchStats() {
      try {
        state.isLoading = true;
        const { data:analytic } = await axios.get("/analytics/dashboard-stats");

        console.log("Analytics : ",analytic.data)

        state.stats = analytic.data;

        console.log("state.stats.clients.available : ",state.stats.clients.available)
        console.log("state.stats.clients.disable : ",state.stats.clients.disable)
        chartData[0] = state.stats.clients.available
        chartData[1] = state.stats.clients.disable

        virtualAssistantChartData[0] = state.stats.virtualAssistant.assigned
        virtualAssistantChartData[1] = state.stats.virtualAssistant.nonAssigned

        projectData[0] = state.stats.projects

      } catch (err) {
        console.error(err);
      } finally {
        state.isLoading = false;
      }

    }

    async function fetchTableData() {
      try {
        loading.value = true;
        const { data:ticketsData } = await axios.get(`${SUPPORT_API_PATH}/tickets?page=${page.value}`);
        clients.value = ticketsData.data.tickets
        page.value = parseInt(ticketsData.data.page);
        itemsPerPage.value = ticketsData.data.itemsPerPage;
        totalItems.value = ticketsData.data.total;
      } catch (err) {
        console.log('Error: ',err)
      } finally {
        loading.value = false;
        console.log('Done loading',clients.value)
      }
    }

    async function deleteSupportMessage(id) {
      try {
        axios.delete(`/support/${id}`)
        setAlertData('Message Deleted Successfully', false, true, 2500)
        clients.value = clients.value.filter(user => user.id != id)
      } catch (err) {
        setAlertData('Message Not Deleted', true, true, 2500)
        console.log('Error : ', err)
        throw err
      }
    }

    const setAlertData = (msg = 'Hello', err, show, duration) => {
      message.value = msg
      isError.value = err
      isVisible.value = show

      setTimeout(() => {
        message.value = null
        isError.value = false
        isVisible.value = false
      }, duration)
    }

    const filterOnlyCapsText = (value, search, item) => {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLowerCase().indexOf(search) !== -1
    }

     function handlePageChange(event) {
      page.value = parseInt(event);
      fetchTableData();
    }

    function navigateToRoute(route) {
      this.$router.push({ name: route });
    }

    return {
      state,
      userData,
      navigateToRoute,
      virtualAssistantData,
      virtualAssistantOptions,
      data,
      options,
      chartProjectData,
      chartData,
      sortBy,
      sortDesc,
      itemsPerPage,
      totalItems,
      page,
      handlePageChange,
      switchloading,
      loading,
      headers,
      clients,
      TableWithFilters,
      filterOnlyCapsText,
      deleteSupportMessage,
      isVisible,
      isError,
      message,
      s3_url,
      suppoty_bucket,
      formatDate,

      icons: {
        mdiChevronRight,
        mdiMessageFast,
        mdiAccountMultipleCheck
      },
    };
  },
};
</script>

<style scoped>
.text-start {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.popup-message {
  position: fixed;
  right: 10px;
  top: 75px;
  width: 300px !important;
  z-index: 999;
  padding: 0.8rem;
  border-radius: 10px;
  font-weight: 500;
}

.support-message-box {
  width: 100% !important;
  background: #fff;
  padding: 10px 30px 10px 20px;
  border-radius: 8px;

}

@media screen and (max-width: 786px) {
  .card {
    width: 100% !important;
  }

  .big-box {
    width: 100% !important;
  }
}

@media screen and (min-width: 787px) and (max-width:1080px) {
  .card {
    width: 48% !important;
  }

  .big-box {
    width: 60% !important;
  }
}

.router-links {
  text-decoration: none;
  color: #EE0815;
  /* color: #001247; */
}

.inner-box-icons {
  /* color: #ffc548; */
  /* color: #EE0815; */
  color: #41557b;
}

.big-box {
  width: 36%;
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}

.inner-big-box {
  width: 100% !important;
  background: #fff;
  padding: 10px 30px 10px 20px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}

.number-of-projects {
  font-size: 2.4rem;
}

.box-title,
.card-heading,
.welcome-title {
  font-size: 0.9rem;
  letter-spacing: 2px;
  text-align: left;
  text-transform: uppercase;
}

.welcome-title {
  font-size: 2rem;
  letter-spacing: 1.5px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
}

.card {
  background-color: #fff;
  width: 30%;
  max-width: 100%;
  padding: 20px 30px 0px 30px;
  border-radius: 8px;
}

.main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .card-heading {
  font-size: 24px;
} */

.total-project {
  font-size: 4.8rem;
}
</style>